/* eslint-disable */
require('script-loader!file-saver');
// require('script-loader!./../excel/Blob');
require('./Blob');//转二进制用
require('script-loader!xlsx/dist/xlsx.core.min');
function generateArray(table) {
    let out = [];
    let rows = table.querySelectorAll('tr');
    let ranges = [];
    for (let R = 0; R < rows.length; ++R) {
        let outRow = [];
        let row = rows[R];
        let columns = row.querySelectorAll('td');
        for (let C = 0; C < columns.length; ++C) {
            let cell = columns[C];
            let colspan = cell.getAttribute('colspan');
            let rowspan = cell.getAttribute('rowspan');
            let cellValue = cell.innerText;
            if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

            //Skip ranges
            ranges.forEach(function (range) {
                if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
                    for (let i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
                }
            });

            //Handle Row Span
            if (rowspan || colspan) {
                rowspan = rowspan || 1;
                colspan = colspan || 1;
                ranges.push({s: {r: R, c: outRow.length}, e: {r: R + rowspan - 1, c: outRow.length + colspan - 1}});
            }
            ;

            //Handle Value
            outRow.push(cellValue !== "" ? cellValue : null);

            //Handle Colspan
            if (colspan) for (let k = 0; k < colspan - 1; ++k) outRow.push(null);
        }
        out.push(outRow);
    }
    return [out, ranges];
};

function datenum(v, date1904) {
    if (date1904) v += 1462;
    let epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts) {
    let ws = {};
    let range = {s: {c: 10000000, r: 10000000}, e: {c: 0, r: 0}};
    for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let cell = {v: data[R][C]};
            if (cell.v == null) continue;
            let cell_ref = XLSX.utils.encode_cell({c: C, r: R});
            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}

function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
}

function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export function export_table_to_excel(id) {
    let theTable = document.getElementById(id);
    let oo = generateArray(theTable);
    let ranges = oo[1];

    /* original data */
    let data = oo[0];
    let ws_name = "SheetJS";

    let wb = new Workbook(), ws = sheet_from_array_of_arrays(data);

    /* add ranges to worksheet */
    // ws['!cols'] = ['apple', 'banan'];
    ws['!merges'] = ranges;

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});

    saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), "test.xlsx")
}

function formatJson(jsonData) {
    console.log(jsonData)
}
export function export_json_to_excel(th, jsonData, defaultTitle) {

    /* original data */

    let data = jsonData;
    data.unshift(th);
    let ws_name = "SheetJS";

    let wb = new Workbook(), ws = sheet_from_array_of_arrays(data);
    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});
    let title = defaultTitle || '列表';
    saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), title + ".xlsx")
}
//多个sheet
export function export_json_to_excel1(th,titleNameArr, jsonData, defaultTitle) {

    /* original data */

    let data = jsonData;
    let ws_name = [];
    // debugger
    for (var i = 0; i < data.length; i++) {
        //添加标题
        data[i].unshift(th)
    }
    for (var i = 0; i < titleNameArr.length; i++) {
        //定义sheet的名称
        ws_name.push(titleNameArr[i])
    }

    let wb = new Workbook(), ws =[];
    //数据转换
    for (var j = 0; j < data.length; j++) {
        let arr = JSON.parse(JSON.stringify(data))
        arr[j].forEach((item, index) => {
            let arr = item[2].split("-")
            item[2] = arr[0]
        })
        ws.push(sheet_from_array_of_arrays(arr[j]))
    }
    /* add worksheet to workbook */
    //生成多个sheet
    for (var k = 0; k < titleNameArr.length; k++) {
        //合并单元格
        let arr1 = []
        data[k].forEach((item, index) => {
            let arr = item[2].split("-")
            if (arr[2] > 1) {
                let s = {
                    r: Number(arr[1])+1,
                    c: 0
                }
                let e = {
                    r: Number(arr[2]),
                    c:0
                }
                let s1 = {
                    r: Number(arr[1])+1,
                    c: 1
                }
                let e1 = {
                    r: Number(arr[2]),
                    c: 1
                }
                arr1.push({
                    s,
                    e
                }, {
                    s: s1,
                    e: e1
                })
            }
        })
        ws[k]['!merges'] = [...uniqueJsonArray(arr1)];
        wb.SheetNames.push(ws_name[k])
        wb.Sheets[ws_name[k]] = ws[k]
    }
    let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});
    let title = defaultTitle || '列表';
    saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), title + ".xlsx")
}
//多个sheet 支付记录
export function export_json_to_excel2(th,th1,titleNameArr, jsonData, defaultTitle,list) {

    /* original data */

    let data = jsonData;
    let ws_name = [];
    //添加标题
    data[0].unshift(th)
    data[1].unshift(th1)
    for (var i = 0; i < titleNameArr.length; i++) {
        //定义sheet的名称
        ws_name.push(titleNameArr[i])
    }

    let wb = new Workbook(), ws =[];
    //数据转换
    for (var j = 0; j < data.length; j++) {
        ws.push(sheet_from_array_of_arrays(data[j]))
    }
    
    /* add worksheet to workbook */
    //生成多个sheet
    for (var k = 0; k < titleNameArr.length; k++) {
        //合并单元格
        let arr1 = []
        if(k == 1){
            arr1 = getTableS(list)
        }
        ws[k]['!merges'] = [...uniqueJsonArray(arr1)];
        wb.SheetNames.push(ws_name[k])
        wb.Sheets[ws_name[k]] = ws[k]
    }
    let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});
    let title = defaultTitle || '列表';
    saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), title + ".xlsx")
}
//材料清单
export function export_json_to_excel3(th,th2, jsonData, defaultTitle, merges) {

    /* original data */

    let data = jsonData;
    data.unshift(th2);
    data.unshift(th);
    let ws_name = "SheetJS";

    let wb = new Workbook(), ws = sheet_from_array_of_arrays(data);
    if(merges.length > 0) {
        if(!ws['!merges']) ws['!merges'] = []
        merges.forEach(item => {
            ws['!merges'].push(XLSX.utils.decode_range(item))
        })
    }
    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'binary'});
    let title = defaultTitle || '列表';
    saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), title + ".xlsx")
}
const getTableS = (array) => {
    const formateData = array.map(item => {
        return item
      })
    const spanMap = formateData.reduce((prev, next, index) => {
        const { changeNo, changeTime, changeType } = next
        const changeNoKey = `changeNo_${changeNo}`
        const changeTimeKey = `changeTime_${changeTime}`
        const changeTypeKey = `changeType_${changeType}`
        if (!prev[changeNoKey]) {
          const num = findKeyNum('changeNo', changeNo)
          prev[changeNoKey] = `${index}_${num}`
        }
        if (!prev[changeTimeKey]) {
          const num = findKeyNum('changeTime', `${changeTime}`)
          prev[changeTimeKey] = `${index}_${num}`
        }
        if (!prev[changeTypeKey]) {
          const num = findKeyNum('changeType', `${changeType}`)
          prev[changeTypeKey] = `${index}_${num}`
        }
        return prev
      }, {})
    //   console.log(spanMap)
      let arrS = []
      for(var i in spanMap) {
        let arr = i.split('_')
        let arr1 = spanMap[i].split('_')
        let c= 0
        if(arr[0] === 'changeNo'){
            c = 0
        } else if(arr[0] === 'changeTime') {
            c = 1
        } else if(arr[0] === 'changeType') {
            c = 2
        }
        let s = {
            r: Number(arr1[0])+1,
            c: c
        }
        let e = {
            r: Number(arr1[1])+Number(arr1[0]),
            c: c
        }
        arrS.push({
            s,
            e
        })
        
     }
    //  console.log('arrS',arrS);
      function findValue(key, next) {
        const arr = key.split('_')
        const v = arr.reduce((p, n) => {
          p.push(next[n])
          return p
        }, []).join('_')
        return v
      }
    
      function findKeyNum(key, value) {
        return formateData.reduce((prev, next) => {
          if (findValue(key, next) === value) {
            prev += 1
          }
          return prev
        },0)
      }
     return  arrS
}
const uniqueJsonArray = (array) => {
    for(var i=0;i< array.length;i++){
      for(var j = array.length - 1; j > 0; j--){
        if(array[i].s.r ==  array[j].s.r && array[i].e.r ==  array[j].e.r && array[i].s.c ==  array[j].s.c && array[i].e.c ==  array[j].e.c &&i != j){
          array.splice(j, 1);
        }
      }
    }
    return array
  }